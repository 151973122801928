import { useFlags } from "@resource/client-ffs";
import FeatureFlags from "generated/FeatureFlags";

export function NotificationBanner() {
  const { [FeatureFlags.V_1_SYSTEM_BANNER]: bannerText } = useFlags();

  if (!bannerText) {
    return null;
  }

  return (
    <div className="text-body-lg bg-yellow-100 text-yellow-900 px-4 py-1 border-b border-yellow-200">
      {bannerText}
    </div>
  );
}
