const BasePathEnum = {
  Home: "/",
  Account: "/account",
  CO2: "/co2",
  Guide: "/guide",
  Templates: "/templates",
  Edit: "/edit",
  Settings: "/settings",
  Create: "/create",
  Admin: "/admin",
  Send: "/send",
  Interviewers: "/interviewers",
  EmailTemplates: "/email-templates",
  Scheduler: "/scheduler",
  PitchPages: "/pitch-pages",
  LegacySchedulerPage: "/scheduler",
  Onboarding: "/onboarding",
  Upsell: "/upsell",
  SchedulerPage: "/s",
  PitchPage: "/p",
  404: "/not-found",
};

export default BasePathEnum;
