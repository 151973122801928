import clsx from "clsx";

import AccountMenu from "./AccountMenu";
import { HelpMenu } from "./HelpMenu";
import OrganizationSwitcher from "./OrganizationSwitcher";

// app header
// ----------

type AppHeaderProps = {
  isOverflowing?: boolean;
};

function AppHeader({ isOverflowing }: AppHeaderProps) {
  return (
    <div
      className={clsx(
        "sticky top-0 h-16 bg-white px-4 flex items-center gap-3 border-b transition-colors z-10",
        {
          "border-transparent": !isOverflowing,
          "border-[#eeedef]": isOverflowing,
        }
      )}
    >
      {/* TODO: page title */}
      <div className="flex-grow" />
      <OrganizationSwitcher />
      <div className="flex">
        <HelpMenu />
      </div>
      <AccountMenu hideDashboard />
    </div>
  );
}

export default AppHeader;
