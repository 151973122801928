import { Button } from "@resource/atlas";
import * as Sentry from "@sentry/nextjs";
import { Component, ComponentType, ErrorInfo } from "react";

// default fallback
// ----------------

export type ErrorFallbackUIProps = {
  onRecover?: () => void;
};

function DefaultErrorFallbackUI({ onRecover }: ErrorFallbackUIProps) {
  return (
    <div className="flex flex-col items-center justify-center gap-[1.5rem] bg-light-gray-200 rounded py-[2rem]">
      <p className="text-h4 text-dark">Sorry. Something went wrong.</p>
      {onRecover && (
        <Button variant="dark" size="medium" onClick={onRecover}>
          Refresh
        </Button>
      )}
    </div>
  );
}

// error boundary
// --------------

type RecoverHandler = (clear: () => void) => void;

type ErrorBoundaryProps = {
  fallback?: ComponentType<ErrorFallbackUIProps>;
  onRecover?: RecoverHandler;
  onError?: (data: {
    error: Error;
    errorInfo: ErrorInfo;
    retries: number;
  }) => boolean | void;
};

type State = {
  retries: number;
  error: Error | undefined;
  errorInfo: ErrorInfo | undefined;
};

class ErrorBoundary extends Component<ErrorBoundaryProps, State> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { retries: 0, error: undefined, errorInfo: undefined };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Sentry.captureException(error);
    const { onError } = this.props;
    const retried = onError?.({
      error,
      errorInfo,
      // eslint-disable-next-line react/destructuring-assignment
      retries: this.state.retries,
    });
    if (!retried) this.setState({ retries: 0, error, errorInfo });
    else this.setState(({ retries }) => ({ retries: retries + 1 }));
  }

  resetError() {
    this.setState({ error: undefined, errorInfo: undefined });
  }

  render() {
    const { children, onRecover, fallback } = this.props;
    const { error, errorInfo } = this.state;
    const FallbackComponent = fallback;
    const fallbackProps = {
      onRecover: onRecover
        ? () => onRecover?.(() => this.resetError())
        : undefined,
      error,
    };

    if (!error && !errorInfo) return children;
    if (!FallbackComponent)
      return <DefaultErrorFallbackUI {...fallbackProps} />;

    return <FallbackComponent {...fallbackProps} />;
  }
}

export default ErrorBoundary;
