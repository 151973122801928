import { gql, useMutation } from "@apollo/client";
import {
  AtlasIconData,
  Button,
  Dialog,
  Icon,
  ListItem,
  useToast,
} from "@resource/atlas";
import {
  atlasBarChart,
  atlasBubble,
  atlasCalendarAlt,
  atlasCircleChecked,
  atlasCircleStar,
  atlasEmoji,
  atlasGear,
  atlasGuide,
  atlasInbox,
  atlasJobs,
  atlasSourcing,
  atlasTemplate,
} from "@resource/atlas/icons";
import { useFlags } from "@resource/client-ffs";
import { strings } from "@resource/common";
import { useAuthContext } from "auth/context";
import clsx from "clsx";
import BasePathEnum from "enums/BasePathEnum";
import FeatureFlags from "generated/FeatureFlags";
import { SendUpgradeRequestEmail } from "generated/schemaTypes";
import Link from "next/link";
import { useRouter } from "next/router";
import { ReactNode, useCallback, useMemo, useRef, useState } from "react";
import { analytics } from "react-hooks/useAnalytics";
import { formatEntity } from "shared/constants/entities";
import { PermissionEnum } from "utils/permissions";
import { getTrialData } from "utils/trial";

import GuideLogoSVG from "./GuideLogoSVG";
import { useLayoutContext } from "./Layout";

// nav link data
// -------------

type NavItem = {
  icon: AtlasIconData;
  url: string;
  analyticsMessage: string;
  title: string;
  trailingContent?: ReactNode;
  hide: () => boolean;
};

function useNavItems() {
  const {
    user,
    ssoProvider,
    checkRolePermissions,
    zeusInfo: { isZeus },
  } = useAuthContext();

  const {
    [FeatureFlags.ENABLE_FEEDBACK_ADDON]: enableFeedbackAddon,
    [FeatureFlags.GATED_FEEDBACK_LANDING_PAGE]: enableGatedFeedbackLandingPage,
    [FeatureFlags.ENABLE_SOURCING_ADDON]: enableSourcingAddon,
    [FeatureFlags.ENABLE_SCHEDULER_ADDON]: enableSchedulerAddon,
    [FeatureFlags.ENABLE_CHAT_ADDON]: enableChatAddon,
    [FeatureFlags.GATED_SOURCING_LANDING_PAGE]: enableGatedSourcingLandingPage,
    [FeatureFlags.GATED_CHAT_LANDING_PAGE]: enableGatedChatLandingPage,
    [FeatureFlags.CUSTOMER_TRIAL]: enableCustomerTrial,
    [FeatureFlags.CANDIDATE_FEEDBACK_ZEUS]: zeusCandidateFeedbackEnabled,
    [FeatureFlags.DASHBOARD_INBOX]: enableDashboardInbox,
    [FeatureFlags.DISABLE_DASHBOARD_GUIDES]: disableDashboardGuides,
    [FeatureFlags.DISABLE_INSIGHTS]: disableInsights,
  } = useFlags();
  const requestAvailabilityEnabled = isZeus;

  const hasLimitedAccess = !!user?.currentUserMembership?.hasLimitedAccess;
  const isPitchPageOnboardingComplete = !!user?.pitchPageOnboardingComplete;
  const customer = user?.currentOrganization?.customer;
  const trialData = getTrialData({
    trialDuration: customer?.trialDuration,
    trialStart: customer?.trialStart,
  });
  const isOnTrial = enableCustomerTrial && trialData.isTrial;

  return useMemo(
    (): NavItem[] => [
      {
        title: "Inbox",
        icon: atlasInbox,
        url: "/inbox",
        hide: () => !enableDashboardInbox,
        analyticsMessage: "Inbox",
      },
      {
        title: "Guides",
        icon: atlasGuide,
        url: "/",
        hide: () => hasLimitedAccess || disableDashboardGuides,
        analyticsMessage: "NavBar Button Guides Clicked",
      },
      {
        title: "Templates",
        icon: atlasTemplate,
        url: "/templates",
        hide: () =>
          hasLimitedAccess ||
          !checkRolePermissions(PermissionEnum.TEMPLATE_WRITE),
        analyticsMessage: "NavBar Button Templates Clicked",
      },
      {
        title: "Jobs",
        icon: atlasJobs,
        url: hasLimitedAccess ? "/upsell" : "/jobs",
        hide: () => !checkRolePermissions(PermissionEnum.JOURNEY_WRITE),
        analyticsMessage: "NavBar Button Journeys Clicked",
      },
      {
        title: enableGatedSourcingLandingPage ? "Sourcing" : "Pitch Pages",
        icon: atlasSourcing,
        url: isPitchPageOnboardingComplete
          ? BasePathEnum.PitchPages
          : `${BasePathEnum.Onboarding}/?step=${
              hasLimitedAccess ? "company" : "edit"
            }`,
        hide: () =>
          !checkRolePermissions(PermissionEnum.PITCH_PAGE_TEMPLATE_WRITE) ||
          ((!enableGatedSourcingLandingPage || isOnTrial) &&
            !enableSourcingAddon) ||
          (isZeus && !enableSourcingAddon),
        analyticsMessage: "Pitch Pages Nav Clicked",
        trailingContent: !enableSourcingAddon ? (
          <Icon content={atlasCircleStar} className="text-yellow-500" />
        ) : null,
      },
      {
        title: "Insights",
        icon: atlasBarChart,
        url: "/insights",
        hide: () =>
          hasLimitedAccess ||
          !checkRolePermissions(PermissionEnum.ANALYTICS_READ) ||
          isZeus ||
          disableInsights,
        analyticsMessage: "NavBar Button Insights Clicked",
      },
      {
        title: isZeus
          ? formatEntity("survey", { plural: true, capitalize: true })
          : "Feedback",
        icon: atlasEmoji,
        url: "/surveys",
        hide: () =>
          hasLimitedAccess ||
          !checkRolePermissions(PermissionEnum.ANALYTICS_READ) ||
          ((isOnTrial || !enableGatedFeedbackLandingPage) &&
            !enableFeedbackAddon &&
            !isZeus) ||
          (isZeus && !zeusCandidateFeedbackEnabled),
        analyticsMessage: "NavBar Button Feedback Clicked",
        // If a v1 user without feedback, and our gated landing pages are enabled, show the "upgrade" star
        trailingContent:
          enableGatedFeedbackLandingPage && !enableFeedbackAddon && !isZeus ? (
            <Icon content={atlasCircleStar} className="text-yellow-500" />
          ) : undefined,
      },
      {
        title: "Scheduler",
        icon: atlasCalendarAlt,
        url: BasePathEnum.Scheduler,
        hide: () =>
          ssoProvider === "microsoft" ||
          !enableSchedulerAddon ||
          requestAvailabilityEnabled,
        analyticsMessage: "Scheduler Nav Clicked",
      },
      {
        title: "Live Chat",
        icon: atlasBubble,
        url: "/live-chat",
        hide: () => isOnTrial || !enableGatedChatLandingPage || enableChatAddon,
        analyticsMessage: "Live Chat Clicked",
        trailingContent: !enableChatAddon ? (
          <Icon content={atlasCircleStar} className="text-yellow-500" />
        ) : null,
      },
      {
        title: "Settings",
        icon: atlasGear,
        hide: () => false,
        url: "/settings/company",
        analyticsMessage: "NavBar Button Settings Clicked",
      },
    ],
    [
      zeusCandidateFeedbackEnabled,
      enableGatedSourcingLandingPage,
      isPitchPageOnboardingComplete,
      hasLimitedAccess,
      enableSourcingAddon,
      enableGatedFeedbackLandingPage,
      enableFeedbackAddon,
      enableChatAddon,
      checkRolePermissions,
      isOnTrial,
      ssoProvider,
      disableInsights,
      enableSchedulerAddon,
      requestAvailabilityEnabled,
      enableGatedChatLandingPage,
      enableDashboardInbox,
      disableDashboardGuides,
      isZeus,
    ]
  );
}

// nav logo
// --------

function NavLogo() {
  return (
    <div className="px-4 py-[1.125rem] w-[16rem]">
      <Link href="/" aria-label="Home">
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/anchor-is-valid */}
        <a
          className="focus:focus-ring-0"
          onClick={() => analytics.track("NavBar Button Resource Logo Clicked")}
          // prevent keyboard focus to prevent issues with keyboard navigation
          // the first nav link is the same, so it doesn't break accessibility
          tabIndex={-1}
          // remove focus to prevent the nav from remaining expanded after navigation
          onPointerUp={(e) => e.currentTarget.blur()}
        >
          <GuideLogoSVG
            className="h-7 w-auto"
            textClassName="transition-opacity opacity-0 group-hover:opacity-100 group-focus-within:opacity-100 lg:opacity-100"
          />
        </a>
      </Link>
    </div>
  );
}

// nav links
// ---------

type NavLinkProps = Omit<NavItem, "hide"> & {
  hide: boolean | (() => boolean);
  isDisabled?: boolean;
  currentPath: string;
  isFirst?: boolean;
};

function NavLink({
  hide,
  isDisabled,
  currentPath,
  isFirst,
  icon,
  url,
  title,
  analyticsMessage,
  trailingContent,
}: NavLinkProps) {
  const { restorePageFocus } = useLayoutContext();

  const linkRef = useRef<HTMLAnchorElement>(null);

  if ((typeof hide === "function" ? hide() : hide) && !isDisabled) return null;

  const isActive =
    url === "/" ? currentPath === url : currentPath.startsWith(url);

  function moveFocus(direction: "up" | "down") {
    if (!linkRef.current) return;
    let element: HTMLElement | null;
    if (direction === "up")
      element = linkRef.current.previousElementSibling as HTMLElement | null;
    else element = linkRef.current.nextElementSibling as HTMLElement | null;

    if (element?.classList.contains("nav-link")) {
      element?.scrollIntoView?.({ block: "nearest" });
      element?.focus?.({ preventScroll: true });
    }
  }

  const listItem = (
    <ListItem
      icon={icon}
      isDisabled={isDisabled}
      isSubtleIcon
      isInteractive
      isSelected={isActive}
      onClick={() => analytics.track(analyticsMessage)}
      trailingContent={trailingContent}
    >
      {title}
    </ListItem>
  );

  return isDisabled ? (
    listItem
  ) : (
    <Link href={url}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions */}
      <a
        ref={linkRef}
        data-atlas-wrapper="ListItem"
        className={clsx(
          "nav-target nav-link focus:outline-none scroll-m-[2rem]",
          {
            first: isFirst,
            active: isActive,
          }
        )}
        // remove focus to prevent the nav from remaining expanded after navigation
        onPointerUp={(e) => e.currentTarget.blur()}
        onKeyDown={(e) => {
          // close on escape
          if (e.key === "Escape") {
            restorePageFocus();
            e.stopPropagation();
          }

          // arrow navigation
          if (
            ["ArrowUp", "ArrowDown", "j", "k"].includes(e.key) &&
            (e.target as Element | null)?.classList?.contains("nav-link")
          ) {
            moveFocus(["ArrowUp", "k"].includes(e.key) ? "up" : "down");
            e.preventDefault();
          }
        }}
      >
        {listItem}
      </a>
    </Link>
  );
}

function NavLinks() {
  const navItems = useNavItems();
  const router = useRouter();
  const { user, prospectOnboardingComplete } = useAuthContext();

  return (
    <div className="pb-4 pt-2 px-2 overflow-y-auto flex-grow">
      {navItems.map(({ hide, ...data }, i) => (
        <NavLink
          {...data}
          hide={!user || hide}
          isDisabled={!prospectOnboardingComplete}
          currentPath={router.pathname}
          key={data.url}
          isFirst={i === 0}
        />
      ))}
    </div>
  );
}

// trial
// -----

// - collapsed nav UI

type TrialCollapsedProps = {
  daysLeft: number;
};

function TrialCollapsed({ daysLeft }: TrialCollapsedProps) {
  // clamp value to prevent it from going negative
  const daysLeftClamped = daysLeft >= 0 ? daysLeft : 0;
  return (
    <div
      className={clsx(
        "m-2 rounded-md bg-purple-50 pt-2 pb-2 text-center text-dark",
        "group-hover:hidden group-focus-within:hidden lg:hidden"
      )}
    >
      <p className="text-body-lg-heavy leading-none">{daysLeftClamped}</p>
      <p className="text-body-sm text-[.5rem]">
        {strings.pluralize("day", daysLeftClamped, false)} left
      </p>
    </div>
  );
}

// - text

type TrialTextProps = {
  daysLeft: number;
  isDone: boolean;
};

function TrialText({ daysLeft, isDone }: TrialTextProps) {
  return (
    <p className="text-dark leading-[0] truncate">
      {isDone ? (
        <span className="text-body-sm">Your trial has ended.</span>
      ) : (
        <>
          <span className="text-body-sm-heavy">
            {strings.pluralize("day", daysLeft)}
          </span>
          <span className="text-body-sm">
            {" "}
            {daysLeft === 1 ? "remains" : "remain"} in your trial.
          </span>
        </>
      )}
    </p>
  );
}

// - progress bar

type TrialProgressBarProps = {
  daysLeft: number;
  trialDuration: number;
};

function TrialProgressBar({ daysLeft, trialDuration }: TrialProgressBarProps) {
  const percentLeft = Number(
    ((daysLeft / (trialDuration + 1)) * 100).toFixed(2)
  );
  const percentComplete = Math.min(100 - percentLeft, 100);
  return (
    <div className="h-[.375rem] rounded-lg bg-purple-100 relative">
      <div
        className="absolute rounded-lg h-full top-0 left-0 bg-purple-500 w-[var(--percent-complete)]"
        // @ts-expect-error - Setting a CSS var.
        style={{ "--percent-complete": `${percentComplete}%` }}
      />
    </div>
  );
}

// - upgrade confirmation dialog

type TrialUpgradeConfirmationDialogProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
};

function TrialUpgradeConfirmationDialog({
  open,
  setOpen,
}: TrialUpgradeConfirmationDialogProps) {
  return (
    <Dialog.Root open={open} setOpen={setOpen}>
      <Dialog.Content size="xs">
        <div className="flex flex-col gap-4 items-center">
          <Icon
            content={atlasCircleChecked}
            size="custom"
            className="w-12 h-12 text-green-500"
          />
          <p className="text-dark text-body-md text-center">
            Thanks for your interest in upgrading your plan. A member of our
            team will reach out to you soon.
          </p>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
}

// - upgrade button and dialog

export const SEND_UPGRADE_REQUEST_EMAIL = gql`
  mutation SendUpgradeRequestEmail {
    sendUpgradeRequestEmail {
      success
    }
  }
`;

type TrialUpgradeProps = {
  daysLeft: number;
  isDone: boolean;
};

function TrialUpgrade({ daysLeft, isDone }: TrialUpgradeProps) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [autoFocusOnHide, setAutoFocusOnHide] = useState(true);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const primaryButtonRef = useRef<HTMLButtonElement>(null);

  const [sendUpgradeRequestEmail, { loading }] =
    useMutation<SendUpgradeRequestEmail>(SEND_UPGRADE_REQUEST_EMAIL);

  const { sendToast, dismissToast } = useToast();

  const onPrimaryClick = useCallback(async () => {
    const { data } = await sendUpgradeRequestEmail();

    if (!data?.sendUpgradeRequestEmail.success) {
      const key = sendToast("There was an error with your upgrade request.", {
        variant: "error",
        description:
          "Please contact our sales team at sales@guide.co and we'll get in touch!",
        actions: [
          { label: "Dismiss", onClick: () => dismissToast(key) },
          {
            label: "Send email",
            href: "mailto:sales@guide.co?subject=Please%20upgrade%20my%20Guide%20plan&body=I'd%20like%20to%20request%20an%20upgrade%20to%20my%20Guide%20plan.",
            target: "_blank",
            rel: "noreferer",
          },
        ],
      });
      return;
    }

    setAutoFocusOnHide(false);

    setDialogOpen(false);
    setConfirmationDialogOpen(true);
  }, [dismissToast, sendToast, sendUpgradeRequestEmail]);

  return (
    <>
      <Dialog.Root
        open={dialogOpen}
        setOpen={(value) => {
          if (value) setAutoFocusOnHide(true);
          setDialogOpen(value);
        }}
      >
        <Dialog.Trigger>
          <Button variant="purple" className="nav-target w-full">
            Upgrade plan
          </Button>
        </Dialog.Trigger>
        <Dialog.Content
          initialFocusRef={primaryButtonRef}
          autoFocusOnHide={autoFocusOnHide}
          size="small"
          header={{ title: "Upgrade plan" }}
          footer={{
            leftActions: (
              <Button
                className="ml-[-1rem]"
                onClick={() => setDialogOpen(false)}
              >
                Close
              </Button>
            ),
            rightActions: (
              <Button
                variant="primary"
                ref={primaryButtonRef}
                onClick={onPrimaryClick}
                isLoading={loading}
              >
                Contact sales to upgrade
              </Button>
            ),
          }}
        >
          <p className="text-dark text-body-md">
            {isDone ? (
              "Your free trial has ended. We hope you enjoyed the benefits of Guide. You will need to upgrade to a paid plan to continue using the Guide platform."
            ) : (
              <>
                You are currently on a free trial. We hope you are enjoying the
                benefits of Guide. Your trial ends in{" "}
                <b>{strings.pluralize("day", daysLeft)}</b>, after which you
                will need to upgrade to a paid plan to continue using the Guide
                platform.
              </>
            )}
          </p>
        </Dialog.Content>
      </Dialog.Root>
      <TrialUpgradeConfirmationDialog
        open={confirmationDialogOpen}
        setOpen={setConfirmationDialogOpen}
      />
    </>
  );
}

// - trial UI (root)

function Trial() {
  const { user } = useAuthContext();

  // bail if feature flag is not enabled
  if (!useFlags()[FeatureFlags.CUSTOMER_TRIAL]) return null;

  const customer = user?.currentOrganization?.customer;

  const trialData = getTrialData({
    trialDuration: customer?.trialDuration,
    trialStart: customer?.trialStart,
  });

  // bail if trial is not active
  if (!trialData.isTrial) return null;

  const { daysLeft, isDone, trialDuration } = trialData;

  return (
    <>
      <TrialCollapsed daysLeft={daysLeft} />
      {/* Instead of using display: hidden in narrow nav, we set its height to 0 so that the upgrade button
      remains accesible to be focused back when the user closes the upgrade dialog. */}
      <div className="overflow-hidden h-0 group-hover:h-auto group-focus-within:h-auto lg:h-auto">
        <div className="m-5 rounded-md bg-purple-50 p-4 space-y-4 overflow-hidden">
          <TrialText daysLeft={daysLeft} isDone={isDone} />
          <TrialProgressBar daysLeft={daysLeft} trialDuration={trialDuration} />
          <TrialUpgrade daysLeft={daysLeft} isDone={isDone} />
        </div>
      </div>
    </>
  );
}

// app nav
// -------

function AppNav() {
  return (
    <div className="flex-shrink-0 bg-white w-[3.75rem] lg:w-[16rem] relative group">
      <div
        className={clsx(
          "absolute z-20 flex flex-col h-full overflow-hidden border-r transition-all",
          "border-[#eeedef] group-hover:border-transparent group-focus-within:border-transparent lg:group-hover:border-[#eeedef] lg:group-focus-within:border-[#eeedef]",
          "group-hover:shadow-20 group-focus-within:shadow-20 lg:group-hover:shadow-none lg:group-focus-within:shadow-none",
          "w-[3.75rem] group-hover:w-[16rem] group-focus-within:w-[16rem] lg:w-[16rem] bg-white"
        )}
      >
        <NavLogo />
        <NavLinks />
        <Trial />
      </div>
    </div>
  );
}

export default AppNav;
