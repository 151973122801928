import { gql, useQuery } from "@apollo/client";
import {
  AtlasMenuContentItem,
  Avatar,
  Icon,
  Menu,
  useMenuState,
} from "@resource/atlas";
import { atlasCircleCross } from "@resource/atlas/icons";
import { useFlags } from "@resource/client-ffs";
import { useAuthContext } from "auth/context";
import FeatureFlags from "generated/FeatureFlags";
import {
  AvailableOrganizations,
  AvailableOrganizations_currentUserPrisma_availableOrganizations as Org,
} from "generated/schemaTypes";
import { chain } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useEventCallback } from "react-hooks/useEventCallback";

import { useFlagsLoading } from "./IdentifyUserWrapper";

const GET_AVAILABLE_ORGANIZATIONS = gql`
  query AvailableOrganizations($teammatesSearch: String) {
    currentUserPrisma {
      id
      availableOrganizations {
        id
        name
        defaultAvatarImageUrl
      }
    }
    teammates(
      limit: 25
      where: { teammateType: ALL_ACTIVE, search: $teammatesSearch }
    ) {
      id
      personalProfile {
        id
        displayName
        displayImageUrl
        userMembership {
          id
        }
      }
    }
  }
`;

function useEnsureOrg(orgs?: Org[]) {
  const { organizationIdCookie, organizationIdCookieLoading, switchToOrg } =
    useAuthContext();
  const done = useRef(false);

  useEffect(() => {
    if (!orgs || done.current || organizationIdCookieLoading) return;
    done.current = true;

    if (organizationIdCookie) return;
    const firstOrg = orgs[0];
    if (firstOrg) switchToOrg(firstOrg.id);
  }, [organizationIdCookie, organizationIdCookieLoading, orgs, switchToOrg]);
}

function OrganizationSwitcher() {
  const {
    user,
    organizationIdCookie,
    organizationIdCookieLoading,
    impersonationMembershipIdCookie,
    switchToOrg,
    impersonateUser,
  } = useAuthContext();
  const { loading: flagsLoading } = useFlagsLoading();
  const [impersonationSearch, setImpersonationSearch] = useState("");

  const impersonationSearchState = useMenuState({
    searchable: true,
    comboboxProps: { setValue: setImpersonationSearch },
  });

  const { [FeatureFlags.HIDE_ORGANIZATION_SWITCHER]: hideOrgSwitcherFlag } =
    useFlags();
  const showOrgSwitcher = !flagsLoading && !hideOrgSwitcherFlag;

  const { data } = useQuery<AvailableOrganizations>(
    GET_AVAILABLE_ORGANIZATIONS,
    impersonationSearch
      ? {
          variables: {
            teammatesSearch: impersonationSearch,
          },
        }
      : undefined
  );
  const orgs = data?.currentUserPrisma?.availableOrganizations;
  const users = data?.teammates;

  // ensure that there's a current org set
  useEnsureOrg(orgs);

  const switchToOrgCb = useEventCallback(switchToOrg);
  const impersonateUserCb = useEventCallback(impersonateUser);

  const items = useMemo(
    () =>
      orgs?.map(
        ({ id, name }): AtlasMenuContentItem => ({
          _type: "item",
          children: name,
          value: id,
          key: id,
          isSelectable: true,
          isSelected: organizationIdCookie === id,
          onSelect: () => switchToOrgCb(id),
        })
      ) ?? [],
    [orgs, switchToOrgCb, organizationIdCookie]
  );

  const userItems: AtlasMenuContentItem[] = useMemo(() => {
    const options: AtlasMenuContentItem[] = chain(users)
      .map(({ personalProfile, id }) => {
        return personalProfile?.userMembership &&
          personalProfile?.userMembership.id !== user?.currentUserMembership?.id
          ? ({
              _type: "item" as const,
              children: personalProfile.displayName,
              renderContent: (props) => (
                <div className="flex items-center gap-2 truncate">
                  <Avatar
                    image={personalProfile.displayImageUrl}
                    name={`${props.children}`}
                    size="small"
                  />{" "}
                  {props.children}
                </div>
              ),
              value: id,
              key: id,
              isSelectable: true,
              isSelected:
                impersonationMembershipIdCookie ===
                personalProfile?.userMembership?.id,
              onSelect: () =>
                impersonateUserCb(personalProfile!.userMembership!.id),
            } as AtlasMenuContentItem)
          : null;
      })
      .compact()
      .value();
    return [
      ...(impersonationMembershipIdCookie
        ? [
            {
              _type: "item" as const,
              children: "Clear impersonation user",
              renderContent: (props) => (
                <div className="flex items-center gap-1.5">
                  <Icon
                    content={atlasCircleCross}
                    className="text-subtle w-4 h-4"
                  />{" "}
                  {props.children}
                </div>
              ),
              value: "impersonation-clear",
              key: "impersonation-clear",
              onSelect: () => impersonateUserCb(""),
            } as AtlasMenuContentItem,
          ]
        : []),
      ...options,
    ];
  }, [users, impersonateUserCb, impersonationMembershipIdCookie, user]);

  const currentOrg = useMemo(
    () => orgs?.find(({ id }) => id === organizationIdCookie),
    [organizationIdCookie, orgs]
  );
  const currentImpersonatedUser = useMemo(() => {
    return users?.find(
      ({ personalProfile }) =>
        personalProfile?.userMembership?.id === impersonationMembershipIdCookie
    );
  }, [impersonationMembershipIdCookie, users]);

  if (!orgs || orgs.length < 2 || organizationIdCookieLoading) return null;

  return showOrgSwitcher ? (
    <div className="flex">
      <Menu.Root placement="bottom-end" searchable>
        <Menu.Trigger>
          <Avatar
            key={currentOrg?.id}
            image={currentOrg?.defaultAvatarImageUrl}
            name={currentOrg?.name}
          />
        </Menu.Trigger>
        <Menu.Content
          isVirtual
          isFastSelection
          items={items}
          className="w-72"
        />
      </Menu.Root>
      {user?.isSuperuser && (
        <Menu.Root state={impersonationSearchState} placement="bottom-end">
          <Menu.Trigger>
            <Avatar
              key={currentImpersonatedUser?.id}
              image={currentImpersonatedUser?.personalProfile?.displayImageUrl}
              name={currentImpersonatedUser?.personalProfile?.displayName}
              className="-ml-2"
            />
          </Menu.Trigger>
          <Menu.Content isFastSelection items={userItems} className="w-72" />
        </Menu.Root>
      )}
    </div>
  ) : null;
}

export default OrganizationSwitcher;
