/* eslint-disable jsx-a11y/anchor-has-content */
import { AtlasMenuContentItem, Button, Menu } from "@resource/atlas";
import { atlasRingQuestion } from "@resource/atlas/icons";
import { useAuthContext } from "auth/context";
import { useMemo } from "react";
import { analytics } from "react-hooks/useAnalytics";
import {
  GUIDE_ACADEMY_PAGE,
  HELP_CENTER_PAGE,
  V2_HELP_CENTER_PAGE,
} from "utils/constants/href";
import useIntercom from "utils/useIntercom";

export function HelpMenu() {
  const { callIntercom } = useIntercom();
  const {
    zeusInfo: { isLegacy },
  } = useAuthContext();

  const items: AtlasMenuContentItem[] = useMemo(
    () => [
      {
        _type: "item",
        children: "Contact support",
        key: "contact-support",
        onSelect: () => analytics.track("Help Menu Contact Support Clicked"),
        renderWrapper: (props) => (
          <button
            className="w-full text-left block"
            type="button"
            {...props}
            onClick={() => callIntercom("show")}
            onKeyUp={() => callIntercom("show")}
          />
        ),
      },
      {
        _type: "item",
        children: "Help center",
        key: "help-center",
        onSelect: () => analytics.track("Account Menu Help Center Clicked"),
        renderWrapper: (props) => (
          <a
            {...props}
            href={isLegacy ? HELP_CENTER_PAGE : V2_HELP_CENTER_PAGE}
            target="_blank"
            rel="noreferrer"
          />
        ),
      },
      ...(isLegacy
        ? [
            {
              _type: "item",
              children: "Guide Academy",
              key: "guide-academy",
              onSelect: () =>
                analytics.track("Help Menu Guide Academy Clicked"),
              renderWrapper: (props) => (
                <a
                  {...props}
                  href={GUIDE_ACADEMY_PAGE}
                  target="_blank"
                  rel="noreferrer"
                />
              ),
            } as AtlasMenuContentItem,
          ]
        : []),
    ],
    [isLegacy, callIntercom]
  );

  return (
    <Menu.Root>
      <Menu.Trigger>
        <Button icon={atlasRingQuestion} isGhost aria-label="Open help menu" />
      </Menu.Trigger>
      <Menu.Content defaultSize="compact" items={items} />
    </Menu.Root>
  );
}
